import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import _get from 'lodash/get';
import { extractGraphqlData } from '@utils/extractors';
import { linkResolver } from '@utils/Prismic/linkResolver';

import Layout from '@components/Layout';
import Images from '@components/Images';
import VacancyLink from '@components/VacancyLink';

interface ISimpleCareersProps {
  data: {
    prismic: {
      allSimple_careers_pages: {
        edges: {
          node: any;
        }[];
      };
    };
  };
}

const SimpleCareers: React.FC<ISimpleCareersProps> = ({ data }) => {
  const doc = extractGraphqlData(data, 'allSimple_careers_pages');
  if (!doc) return null;

  const { page_title, page_description, canonical_tag, page_og_image } = doc.node.seo[0];

  return (
    <>
      <Layout
        title={page_title ? RichText.asText(page_title) : 'Careers'}
        description={page_description && RichText.asText(page_description)}
        firstLevelPage
        seo={{
          path: '/jobs',
          canonical: canonical_tag && canonical_tag.url,
          image: page_og_image ? page_og_image : _get(doc, ['node', 'image_right', 'url']),
        }}
      >
        <div className="knowledge">
          <div className="knowledge__content">
            <div className="knowledge__text">
              <div className="knowledge__text-inner">
                <div className="anim-block">
                  <h1>{RichText.asText(doc.node.heading)}</h1>
                  {RichText.render(doc.node.text, linkResolver)}
                  <div className="knowledge__bottom-spacer">
                    <a
                      href={_get(doc, ['node', 'careers_link', 'url'])}
                      className="our-brands__link career__link"
                      target={_get(doc, ['node', 'careers_link', 'target'])}
                    >
                      <span className="our-brands__link-arrow"></span>
                      <span className="career__link-image"></span>
                      <span className="career__link-text">
                        {_get(doc, ['node', 'careers_link_title'])}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="knowledge__image">
              <Images
                imageData={[{ image: doc.node.image_right }]}
                extractPath="image"
                alt={_get(doc, ['node', 'image_right', 'alt'])}
                classes={{
                  base: 'anim-image',
                  extend: 'knowledge',
                  wrap: 'knowledge__image-img',
                  class: '',
                }}
                width="526"
              />
              <div className="knowledge__vacancies anim-block">
                <VacancyLink doc={doc} />
              </div>
            </div>
            <div className="knowledge__panoramic">
              <Images
                imageData={[{ image: doc.node.image_below }]}
                extractPath="image"
                alt={_get(doc, ['node', 'image_below', 'alt'])}
                classes={{
                  base: 'anim-bottom-image',
                  extend: 'knowledge',
                  wrap: 'knowledge__panoramic-img',
                  class: '',
                }}
                width="1314"
                setHeight={false}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const query = graphql`
  query SimpleCareers {
    prismic {
      allSimple_careers_pages {
        edges {
          node {
            heading
            text
            image_right
            careers_link_title
            careers_link {
              ... on PRISMIC__ExternalLink {
                url
                target
              }
            }
            image_below
            seo {
              page_title
              page_description
              page_og_image
              canonical_tag {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default SimpleCareers;
